import styled from 'styled-components';

export const Themes = styled.nav`
  width: var(--width-m);
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
  & > div {
    display: flex;
    cursor: pointer;
    flex-direction: column;
    img {
      width: 100%;
      padding-right: 10px;
    }
    p {
      background-color: var(--gray);
      padding: 5px 15px;
      margin-right: 10px;
      color: var(--white);
      font-size: 18px;
      font-weight: 700;
      text-transform: uppercase;
    }
  }
  @media screen and (max-width: 720px) {
    width: 100%;
    flex-direction: column;
    margin: 32px 0;
    & > div {
      margin-bottom: 16px;
    }
  }
  @media screen and (max-width: 1300px) and (min-width: 721px) {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 32px 0;
    & > div {
      width: 75%;
      margin: 0 32px 16px;
    }
  }
`;

export const SearchInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 32px;
  > div.search {
    height: 50px;
    width: 568px;
    position: relative;
    display: flex;
    input {
      background-color: ${(props) => props.theme.backgroundColor};
      width: 100%;
      border: none;
      border-radius: 10px;
      height: 50px;
      font-weight: 700;
      padding: 0px 135px 0px 20px;
      color: ${(props) => props.theme.textColor};
      font-size: ${(props) => props.theme.fontSize + 16}px;
      ::placeholder {
        color: ${(props) => props.theme.textColor}88;
        font-size: ${(props) => props.theme.fontSize / 2 + 16}px;
      }
    }
    button {
      position: absolute;
      right: 0;
      top: 0;
      height: 50px;
      width: 115px;
      border-radius: 0px 10px 10px 0px;
      border: none;
      background-color: var(--yellow);
      color: var(--white);
      font-size: ${(props) => props.theme.fontSize + 14}px;
      font-weight: 700;
    }
  }
  > a {
    background: transparent;
    border: none;
    color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    img {
    }
  }
  @media screen and (max-width: 720px) {
    width: 100vw;
    > div.search {
      width: 90%;
      margin: 0 32px;
    }
  }
`;
