/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef, useState } from 'react';
import {
  Aside,
  Card1,
  Container,
  ImageFallback,
  InvisibleLabel,
  Main,
  NoResultsMessage,
} from './styles';

import QueryString from 'qs';
import { useHistory } from 'react-router-dom';
import AuthContext, { STORAGE_KEYS } from '../../contexts/auth';
import api from '../../services/api';
import { CheckboxFilter } from '../CheckboxFilter';
import { Input } from '../Input';
import { Pagination } from '../Pagination';

export const SearchContainer = ({ handleButton, onButtonClicked, filterText, contentFilter }) => {
  const [firstLoad, setFirstLoad] = useState(true)
  const [loading, setLoading] = useState(true);
  const [formatFilter, setFormatFilter] = useState(['TODOS']);

  const [licenseFilter, setLicenseFilter] = useState(['TODOS']);

  const [typeFilter, setTypeFilter] = useState(['TODOS']);

  const [keywordFilter, setKeywordFilter] = useState(['TODOS']);

  const { setSelectedBook } = useContext(AuthContext);
  const isLogged = localStorage.getItem(STORAGE_KEYS.isLogged) === "true"

  const [keywords, setKeywords] = useState([]);

  const fetchForKeywords = async () => {
    try {
      const response = await api.get(`/keywords?_limit=-1`);

      if (response) {
        if (response.status === 200) {
          setKeywords(response.data);
          return;
        }
      }
    } catch (error) {
      console.error(error, 'error while fetching for keywords');
    }
  };

  useEffect(() => {
    fetchForKeywords();
  }, []);

  useEffect(() => {
    if (handleButton === true) {
      mainRef.current.focus()
    }

    if (onButtonClicked) {
      onButtonClicked();
    }
  }, [handleButton]);

  const [possibleLicenseTypes, setPossibleLicenseTypes] = useState([]);

  const fetchForLicenses = async () => {
    try {
      const response = await api.get(`/licenses?_limit=-1`);

      if (response) {
        if (response.status === 200) {
          setPossibleLicenseTypes(response.data);
          return;
        }
      }
    } catch (error) {
      console.error(error, 'error while fetching for licenses');
    }
  };

  useEffect(() => {
    fetchForLicenses();
  }, []);

  useEffect(() => {
    if (contentFilter !== undefined) {
      setFormatFilter([...contentFilter]);
    }
  }, [contentFilter]);

  const [contentList, setContentList] = useState([]);

  const [pagination, setPagination] = useState(0);

  const PAGINATION_SIZE = 10;
  const [booksTotalCount, setBooksTotalCount] = useState(0)

  const possibleFormats = ['TODOS', 'Audiolivro', 'Braille', 'Daisy', 'ePub'];

  const possibleFormatsDescription = [
    'Mostrar todos os formatos',
    'Mostrar conteúdo áudiolivro',
    'Mostrar conteúdo braille',
    'Mostrar conteúdo daisy',
    'Mostrar conteúdo ePub',
  ];

  const possibleLicenses = ['TODOS'].concat(
    possibleLicenseTypes.map(({ name }) => name)
  );

  const possibleLicensesDescription = ['Todas as licenças'].concat(
    possibleLicenses
      .filter((item) => item !== 'TODOS')
      .map((item) => `Licença: ${item}`)
  );

  const possibleTypes = ['TODOS', 'Livros', 'Revistas', 'Jornais', 'Audios'];

  const possibleTypesDescription = [
    'Todos os tipos',
    'Livros',
    'Revistas',
    'Jornais',
  ];

  const possibleKeywords = ['TODOS'].concat(keywords.map(({ name }) => name));

  const possibleKeywordsDescription = ['Todas as palavras-chave'].concat(
    possibleKeywords
      .filter((item) => item !== 'TODOS')
      .map((item) => `Palavra-chave: ${item}`)
  );

  const history = useHistory();

  const handleRedirect = (content) => {
    setSelectedBook(content);
    history.push(`/detalhes-da-obra/${content.id}`);
  };

  const [sorting, setSorting] = useState('');

  const handleSorting = (sortType) => {
    setSorting(sortType);
  };

  let filteredContentList = [...contentList];

  //filtro de texto pelo nome do livro
  useEffect(()=>{
    if(!firstLoad) fetchForBooksTotalCount(filterText, filterTypesExceptForAll, filterFormatTypesExceptForAll, filterOutLicensesExceptoForALl, filterKeywordsForExceptAll, sorting)
  },[filterText])

  //filter keywords
  const filterKeywordsForExceptAll = keywordFilter.filter(
    (item) => item !== 'TODOS'
  );
  useEffect(() => {
    if(!firstLoad) fetchForBooksTotalCount(filterText, filterTypesExceptForAll, filterFormatTypesExceptForAll, filterOutLicensesExceptoForALl, filterKeywordsForExceptAll, sorting)
  }, [keywordFilter])
  
  //filter types
  const typesCatalog = {
    Livros: 'book',
    Revistas: 'magazine',
    Jornais: 'newspaper',
    Audios: 'audio',
  };

  const filterTypesExceptForAll = typeFilter
  .filter((item) => item !== 'TODOS')
  .map((item) => typesCatalog[item]);

  useEffect(()=>{
    if(!firstLoad) fetchForBooksTotalCount(filterText, filterTypesExceptForAll, filterFormatTypesExceptForAll, filterOutLicensesExceptoForALl, filterKeywordsForExceptAll, sorting)
  }, [typeFilter])

  //filter formats
  const formatCatalogs = {
    Audiolivro: 'audiobook',
    Braille: 'braille',
    Daisy: 'daisy',
    ePub: 'epub',
  };

  const filterFormatTypesExceptForAll = formatFilter
    .filter((item) => item !== 'TODOS')
    .map((item) => formatCatalogs[item]);

  useEffect(()=>{
    if(!firstLoad) fetchForBooksTotalCount(filterText, filterTypesExceptForAll, filterFormatTypesExceptForAll, filterOutLicensesExceptoForALl, filterKeywordsForExceptAll, sorting)
  },[formatFilter])

  //filter license
  const filterOutLicensesExceptoForALl = licenseFilter.filter(
    (item) => item !== 'TODOS'
  );

  useEffect(()=> {
    if(!firstLoad) fetchForBooksTotalCount(filterText, filterTypesExceptForAll, filterFormatTypesExceptForAll, filterOutLicensesExceptoForALl, filterKeywordsForExceptAll, sorting)
  },[licenseFilter])

  const sortTypes = ['Lançamento', 'Alfabética', 'Downloads'];

  const sortingDescription = [
    'Ordenar por ano de lançamento',
    'Ordenar por ordem alfabética',
    'Ordenar por quantidade de downloads',
  ];

  const fetchForBooks = async (filterText, typeFilterNew, formatFilterNew, licenseFilterNew, keywordFilterNew, totalCount, sorting) => {
    let url = '/books'

    //filtro texto
    if(filterText && filterText !== ''){  
      const query = QueryString.stringify({ 
        _where: { 
          _or: [
            { bookTitle_contains: filterText }, 
            { author_contains: filterText },
          ] 
        } 
      });
      url += `?${query}&keyword_name=${filterText}&categorie_name=${filterText}`
    }

    //filtro tipo
    if(typeFilterNew && typeFilterNew.length > 0 && !typeFilterNew.includes('TODOS')) {
      for (let i = 0; i < typeFilterNew.length; i++) {
        const element = typeFilterNew[i];
        url += url.includes('?') ? `&type=${element}` : `?type=${element}`
      }
    }

    // filtro formato
    if(formatFilterNew && formatFilterNew.length > 0 && !formatFilterNew.includes('TODOS')) {
      for (let i = 0; i < formatFilterNew.length; i++) {
        const element = formatFilterNew[i];
        url += url.includes('?') ? `&${element}=true` : `?${element}=true`
      }
    }

    //filtro palavra-chave
    if(keywordFilterNew && keywordFilterNew.length > 0 && !keywordFilterNew.includes('TODOS')) {
      for (let i = 0; i < keywordFilterNew.length; i++) {
        const element = keywordFilterNew[i];
        url += url.includes('?') ? `&keywords.name=${element}` : `?keywords.name=${element}`
      }
    }

     //filtro licença
     if(licenseFilterNew && licenseFilterNew.length > 0 && !licenseFilterNew.includes('TODOS')) {
      for (let i = 0; i < licenseFilterNew.length; i++) {
        const element = licenseFilterNew[i];
        url += url.includes('?') ? `&license.name=${element}` : `?license.name=${element}`
      }
    }

    if(sorting !== '') {
      switch (sorting) {
        case 'Downloads':
          url += url.includes('?') ? `&_sort=downloads` : `?_sort=downloads`
          break;
        case 'Lançamento':
          url += url.includes('?') ? `&_sort=publishedDate` : `?_sort=publishedDate`
          break;
        case 'Alfabética':
          url += url.includes('?') ? `&_sort=bookTitle` : `?_sort=bookTitle`
          break;
        default:
          break;
      }
    }
    try {
      const inactiveFilter = QueryString.stringify({
        _and: [{ _or: [{ inactive_null: true }, { inactive: false }] }],
      });

      url += url.includes('?') 
                ? `&_start=${PAGINATION_SIZE * pagination}&_limit=${PAGINATION_SIZE}&${inactiveFilter}` 
                : `?_start=${PAGINATION_SIZE * pagination}&_limit=${PAGINATION_SIZE}&${inactiveFilter}`

      const response = await api.get(url);
      if (response) {
        if (response.status === 200) {
          setContentList(response.data)
          mainRef.current.focus()
          setLoading(false)
          return;
        }
      }
    } catch (error) {
      console.error(error, 'error while fetching for books');
    }
  };

  async function fetchForBooksTotalCount(filterText, typeFilterNew, formatFilterNew, licenseFilterNew, keywordFilterNew, sorting){
    setFirstLoad(false)
    setLoading(true)
    let urlCount = '/books/count'

    //filtro texto  
    if(filterText && filterText !== ''){
      const query = QueryString.stringify({ 
        _where: { 
          _or: [
            { bookTitle_contains: filterText }, 
            { author_contains: filterText }
      ]}})
      urlCount += `?${query}&keyword_name=${filterText}&categorie_name=${filterText}`
    }

    //filtro tipo
    if(typeFilterNew && typeFilterNew.length > 0 && !typeFilterNew.includes('TODOS')) {
      for (let i = 0; i < typeFilterNew.length; i++) {
        const element = typeFilterNew[i];
        urlCount += urlCount.includes('?') ? `&type=${element}` : `?type=${element}`
      }
    }

    // filtro formato
    if(formatFilterNew && formatFilterNew.length > 0 && !formatFilterNew.includes('TODOS')) {
      for (let i = 0; i < formatFilterNew.length; i++) {
        const element = formatFilterNew[i];
        urlCount += urlCount.includes('?') ? `&${element}=true` : `?${element}=true`
      }
    }

    //filtro palavra-chave
    if(keywordFilterNew && keywordFilterNew.length > 0 && !keywordFilterNew.includes('TODOS')) {
      for (let i = 0; i < keywordFilterNew.length; i++) {
        const element = keywordFilterNew[i];
        urlCount += urlCount.includes('?') ? `&keywords.name=${element}` : `?keywords.name=${element}`
      }
    }

    //filtro licença
    if(licenseFilterNew && licenseFilterNew.length > 0 && !licenseFilterNew.includes('TODOS') && isLogged) {
      for (let i = 0; i < licenseFilterNew.length; i++) {
        const element = licenseFilterNew[i];
        urlCount += urlCount.includes('?') ? `&license.name=${element}` : `?license.name=${element}`
      }
    }

    try {
      const inactiveFilter = QueryString.stringify({
        _and: [{ _or: [{ inactive_null: true }, { inactive: false }] }],
      });
      const url = urlCount.includes('?') ? `${urlCount}&${inactiveFilter}` : `${urlCount}?${inactiveFilter}`
      const totalCount = await api.get(url)
      setBooksTotalCount(totalCount.data)
      fetchForBooks(filterText, typeFilterNew, formatFilterNew, licenseFilterNew, keywordFilterNew, totalCount.data, sorting)
    } catch (err) {
      console.error(err, `Error during getting books total count`)
    }
  }

  useEffect(() => {
      fetchForBooksTotalCount(filterText, filterTypesExceptForAll, filterFormatTypesExceptForAll, filterOutLicensesExceptoForALl, filterKeywordsForExceptAll, sorting);
  }, [pagination, sorting]);

  let fallbackTextFilter = filterText ?? '';
  const mainRef = useRef(null)

  return (
    <Container>
      <Main>
        <div ref={mainRef} tabIndex={0}>
          {fallbackTextFilter.length > 0 ? (
            <p tabIndex={1}>
              Resultado de busca para <span>{fallbackTextFilter}</span>{' '}
            </p>
          ) : null}

          {loading ? (
            <p>Buscando...</p>
          ) : (
            <p tabIndex={1}>
            Mostrando{' '}
            <span>
              {Math.min(
                PAGINATION_SIZE * (pagination + 1),
                booksTotalCount
              )}{' '}
              de {booksTotalCount} resultado
            </span>{' '}
          </p>
          )}
        </div>
        {booksTotalCount === 0 ? (
          <NoResultsMessage>
            <p tabIndex={0}>{loading ? null : 'Nenhum resultado encontrado'}</p>
          </NoResultsMessage>
        ) : null}
        {contentList
          .map((content, key) => {
            const action = () => handleRedirect(content);
            return (
              <Card1
                onClick={action}
                key={key + 'content'}
                aria-label={`Ir para Livro: ${content.bookTitle} do autor: ${content.author}`}
              >
                <div>
                  <div className="head">
                    <div className="info">
                      <h3
                        tabIndex={0}
                        onKeyDown={({ keyCode }) =>
                          keyCode === 13 ? action() : null
                        }
                      >
                        <InvisibleLabel>Obra: </InvisibleLabel>
                        {content.bookTitle}
                      </h3>
                      <p tabIndex={1}>
                        <InvisibleLabel>Autor: </InvisibleLabel>
                        {content.author}
                      </p>
                      <span tabIndex={1}>
                        <InvisibleLabel>Categorias: </InvisibleLabel>
                        {content.categories.map(({ name }) => name).join(', ')}
                      </span>
                    </div>
                  </div>
                  <div className="foot">
                    <p tabIndex={1}>
                      <InvisibleLabel>Formatos disponíveis:</InvisibleLabel>
                      {content.braille && <span>Braille</span>}
                      {content.audiobook && <span>Audiolivro</span>}
                      {content.daisy && <span>Daisy</span>}
                      {content.epub && <span>ePub</span>}
                    </p>
                  </div>
                </div>
                <div className="photo">
                  <ImageFallback
                    targetImage={
                      content.images.length > 0
                        ? content.images[0].file
                          ? content.images[0].file?.url
                          : false
                        : false
                    }
                  />
                </div>
              </Card1>
            );
          })}
        <Pagination
          value={pagination}
          onChange={setPagination}
          paginationSize={PAGINATION_SIZE}
          itemQuantity={booksTotalCount}
        />
      </Main>
      <Aside tabIndex={1}>
        <InvisibleLabel>Filtrar resultados da pesquisa</InvisibleLabel>
        <CheckboxFilter
          options={possibleFormats}
          title="Formatos"
          fallback="TODOS"
          selected={formatFilter}
          onChange={setFormatFilter}
          ariaDescription={possibleFormatsDescription}
          setPagination={setPagination}
        />
        {/* { isLogged ? (<CheckboxFilter
          options={possibleLicenses}
          title="Licenças"
          fallback="TODOS"
          selected={licenseFilter}
          onChange={setLicenseFilter}
          ariaDescription={possibleLicensesDescription}
          setPagination={setPagination}
        />) : null} */}
        <CheckboxFilter
          options={possibleTypes}
          title="Tipos"
          fallback="TODOS"
          selected={typeFilter}
          onChange={setTypeFilter}
          ariaDescription={possibleTypesDescription}
          setPagination={setPagination}
        />
        <CheckboxFilter
          options={possibleKeywords}
          title="Palavras-chave"
          fallback="TODOS"
          selected={keywordFilter}
          onChange={setKeywordFilter}
          ariaDescription={possibleKeywordsDescription}
          setPagination={setPagination}
          style={{
            height: '200px',
            overflowY: 'scroll',
          }}
        />
        <div>
          <p>Ordenar por</p>
          <Input
            onChange={handleSorting}
            type="select"
            ariaLabel="Organizar resultados"
          >
            {sortTypes.map((opt, key) => (
              // eslint-disable-next-line jsx-a11y/no-redundant-roles
              <option
                name={sortingDescription[key]}
                role="option"
                aria-label={sortingDescription[key]}
                key={key + 'sorting-list'}
              >
                {opt}
              </option>
            ))}
          </Input>
        </div>
      </Aside>
    </Container>
  );
};
