import { useContext, useEffect, useState, useRef } from "react";
import { Footer } from "../../components/Footer";
import { GoBack } from "../../components/GoBack";
import { Header } from "../../components/Header";
import { Input } from "../../components/Input";
import AuthContext, { STORAGE_KEYS } from "../../contexts/auth";
import ValidationBuilder from "../../data/validation/builder";
import api from "../../services/api";
import { Container, Form } from "./styles";

const emailValidation = ValidationBuilder("email")

export function Contact() {
    const { user } = useContext(AuthContext)
    const [contactType, setContactType] = useState('help')
    const isLogged = localStorage.getItem(STORAGE_KEYS.isLogged) === "true"

    const contactOptions = [
        { label: 'Ajuda', value: 'help' },
        { label: 'Dúvida', value: 'question' }
    ]

    const [targetField, setTargetField] = useState('')

    const [subject, setSubject] = useState('')
    const [message, setMessage] = useState('')
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')

    const focus = useRef()

    let validationPipe = [
        {
            value: subject,
            label: 'subject',
            message: 'O campo assunto é obrigatório'
        },
        {
            value: message,
            label: 'message',
            message: 'O campo mensagem é obrigatório'
        },
    ]

    if (!isLogged) {
        validationPipe = validationPipe.concat([
            {
                value: name,
                label: 'name',
                message: 'O campo nome é obrigatório'
            },
            {
                value: email,
                label: 'email',
                message: 'O campo e-mail é obrigatório'
            }
        ])
    }

    const emptyFields = validationPipe.filter(({ value }) => !value)

    let isEmailValid = true

    if (!isLogged) {
        isEmailValid = !emailValidation.validateString(email).error
    }

    const isFormValid = emptyFields.length === 0 && isEmailValid

    const handleSuccess = async () => {
        const contactForm = {
            subject,
            message,
            type: contactType
        }
        if(isLogged) {
            contactForm.user = {
                id: user.id
            }
        } else {
            contactForm.name = name
            contactForm.email = email
        }
        try {
            await api.post(`/contacts`, contactForm)
            setContactType('help')
            setTargetField('')
            setSubject('')
            setMessage('')
            setName('')
            setEmail('')
            alert('Contato enviado com sucesso!')
        } catch(error) {
            console.log(error)
        }
    }

    const handleValidationError = () => {
        if (emptyFields.length > 0) {
            const firstValidationError = emptyFields[0]
            if (targetField === firstValidationError.label) {
                setTargetField('')
                alert(firstValidationError.message)
                const timedOutSetFocus = setTimeout(() => setTargetField(firstValidationError.label), 16)
                return () => clearTimeout(timedOutSetFocus)
            }

            alert(firstValidationError.message)
            setTargetField(firstValidationError.label)
            return
        }

        const validationError = {
            label: 'email',
            message: 'E-mail inválido'
        }

        if (targetField === validationError.label) {
            setTargetField('')
            alert(validationError.message)
            const timedOutSetFocus = setTimeout(() => setTargetField(validationError.label), 16)
            return () => clearTimeout(timedOutSetFocus)
        }

        alert(validationError.message)
        setTargetField(validationError.label)
    }

    useEffect(() => {
        focus.current?.focus();
    }, [])

    return (
        <>
            <Header menu={6} />
            <GoBack title="Contato" />
            <Container>
                <Form>
                    <div>
                        <label ref={focus} tabIndex={1}>Tipo de Contato</label>
                        <select
                            name=""
                            value={contactType}
                            tabIndex={1}
                            onChange={(e) => setContactType(e.target.value)}
                        >
                            {
                                contactOptions.map(({ label, value }, key) => <option
                                    name={label}
                                    aria-label={label}
                                    key={key + 'opt-type'}
                                    value={value}
                                >
                                    {label}
                                </option>)
                            }
                        </select>
                    </div>
                    <div>
                        <label tabIndex={1} htmlFor="">Assunto</label>
                        <Input
                            value={subject}
                            tabIndex={1}
                            onChange={setSubject}
                            type="text"
                            placeholder="Informe o assunto da mensagem"
                        />
                    </div>
                    <div>
                        <label tabIndex={1} htmlFor="">Mensagem</label>
                        <Input
                            cols="30" rows="10"
                            tabIndex={1}
                            value={message}
                            type="textarea"
                            onChange={setMessage}
                            placeholder="Escreva aqui sua mensagem" />
                    </div>
                    {
                        !isLogged ?
                            <>
                                <div>
                                    <label tabIndex={1} htmlFor="">Nome</label>
                                    <Input
                                        value={name}
                                        tabIndex={1}
                                        onChange={setName}
                                        type="text"
                                        placeholder="Informe seu nome" />
                                </div>
                                <div>
                                    <label tabIndex={1} htmlFor="">E-mail</label>
                                    <Input
                                        value={email}
                                        onChange={setEmail}
                                        tabIndex={1}
                                        type="text"
                                        placeholder="Informe seu E-mail"
                                    />
                                </div>
                            </>
                            : null
                    }
                    <button
                    aria-label="Enviar mensagem de contato"
                    tabIndex={1} 
                    onClick={() => isFormValid ? handleSuccess() : handleValidationError()}
                    style={{
                        opacity: isFormValid ? 1 : .5
                    }}>Enviar</button>
                </Form>
            </Container>
            <Footer />
        </>
    )
}